import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const app = initializeApp(getFirebaseConfig());
const db = getFirestore(app);

function getFirebaseConfig() {
  if (process.env.NODE_ENV === "production") {
    return {
      apiKey: "AIzaSyDPlgt0dByRev7G803MopdJJ5pZpzzZQts",
      authDomain: "linxdot-dashboard.firebaseapp.com",
      projectId: "linxdot-dashboard",
      storageBucket: "linxdot-dashboard.appspot.com",
      messagingSenderId: "374486231972",
      appId: "1:374486231972:web:d428caad86c8630800af31",
    };
  } else {
    return {
      apiKey: "AIzaSyDPlgt0dByRev7G803MopdJJ5pZpzzZQts",
      authDomain: "linxdot-dashboard.firebaseapp.com",
      projectId: "linxdot-dashboard",
      storageBucket: "linxdot-dashboard.appspot.com",
      messagingSenderId: "374486231972",
      appId: "1:374486231972:web:d428caad86c8630800af31",
    };
  }
}

export default db;
