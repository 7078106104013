<template>
  <div>
    <md-table
      v-model="paginatedDevices"
      md-sort="id"
      md-sort-order="asc"
      :table-header-color="tableHeaderColor"
    >
      <md-table-toolbar>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input
            placeholder="Search by animal name..."
            v-model="searchAnimalName"
            @input="searchOnTable"
          />
        </md-field>
        <md-field md-clearable class="md-toolbar-section-end">
          <md-input
            placeholder="Search by mac address..."
            v-model="searchMacAddress"
            @input="searchOnTable"
          />
        </md-field>
        <md-field class="md-toolbar-section-end">
          <label>Status</label>
          <md-select
            v-model="searchStatus"
            name="Status"
            id="status"
            placeholder="Select status..."
          >
            <md-option value="">All</md-option>
            <md-option value="Online">Online</md-option>
            <md-option value="Offline">Offline</md-option>
          </md-select>
        </md-field>
      </md-table-toolbar>
      <md-table-empty-state
        md-label="No device found"
        :md-description="`No device found for this '${searchAnimalName}' query. Try a different search term.`"
      >
      </md-table-empty-state>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Create Time" md-sort-by="createTime"
          >{{ item.createTime }}
        </md-table-cell>
        <md-table-cell md-label="Animal Name"
          >{{ item.animalName }}
        </md-table-cell>
        <md-table-cell md-label="On Boarding Key"
          >{{ item.onBoardingKey }}
        </md-table-cell>
        <md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
        <md-table-cell md-label="Miner Version"
          >{{ item.minerVersion }}
        </md-table-cell>
        <md-table-cell md-label="SN"
          >{{ item.sn }}
        </md-table-cell>
         <md-table-cell md-label="Firmware"
          >{{ item.firmware }}
        </md-table-cell>
        <md-table-cell md-label="Lan Mac Address"
          >{{ item.macAddress }}
        </md-table-cell>
        <md-table-cell md-label="WiFi Mac Address"
          >{{ item.wifiMacAddress }}
        </md-table-cell>
        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-success"
            @click="updateDocker(item.id)"
          >
            <md-icon>autorenew</md-icon>
            <md-tooltip md-direction="top">Update Docker</md-tooltip>
          </md-button>
          <md-button
            class="md-just-icon md-simple md-success"
            @click="reverseSSH(item.id)"
          >
            <md-icon>login</md-icon>
            <md-tooltip md-direction="top">Reverse SSH</md-tooltip>
          </md-button>
          <md-button
            v-if="item.dewiStatus !== 'Onboarded'"
            class="md-just-icon md-simple md-success"
            @click="registerDewi(item.id)"
          >
            <md-icon>devices</md-icon>
            <md-tooltip md-direction="top">Register DeWi</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>

      <md-table-pagination
        :md-page-size="pagination.limit"
        :md-page-options="[10, 20, 30, 40, 50]"
        :md-update="updatePagination"
        :md-data="devices"
        :md-paginated-data.sync="paginatedDevices"
      />
    </md-table>
    <div>
      <md-dialog-alert
        :md-active.sync="dialogInfo.show"
        :md-title="dialogInfo.title"
        :md-content="dialogInfo.content"
      />
    </div>
    <loading :active="loading.show" :can-cancel="false" :is-full-page="true" />
  </div>
</template>

<script>
import Vue from "vue";
import MdTablePagination from "@/components/Tables/MdTablePagination";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "debounce";

export default {
  name: "simple-table",
  inject: ["setLogin"],
  components: { MdTablePagination, Loading },
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pagination: {
        page: 0,
        offset: 0,
        limit: 10,
      },
      searchStatus: "",
      searchMacAddress: "",
      searchAnimalName: "",
      searchDewiStatus: "",
      paginatedDevices: [],
      serverUri: process.env.VUE_APP_SERVER_URL,
      devices: [],
      dialogInfo: {
        show: false,
        title: "",
        content: "",
        status: "md-success",
      },
      loading: {
        show: false,
      },
    };
  },
  mounted() {
    this.getDeviceList();
  },
  watch: {
    searchStatus() {
      this.searchOnTable();
    },
    searchDewiStatus() {
      this.searchOnTable();
    },
  },
  methods: {
    updatePagination(page, pageSize, sort, sortOrder) {
      console.log(
        `pagination has updated, offset: ${page}, limit: ${pageSize}, sort: ${sort}, sortOrder: ${sortOrder}`
      );
      if (page <= 1) {
        this.pagination.page = 0;
        this.pagination.offset = 0;
      } else {
        this.pagination.page = page - 1;
        this.pagination.offset = (page - 1) * pageSize;
      }
      this.pagination.limit = pageSize;
      this.getDeviceList();
    },
    searchOnTable: _.debounce(function (e) {
      const tag = "[searchOnTable]";
      console.log(
        tag,
        "animalName:",
        this.searchAnimalName,
        "mac: ",
        this.searchMacAddress,
        "status: ",
        this.searchStatus,
        "dewi status: ",
        this.searchDewiStatus
      );

      this.getDeviceList();
    }, 500),
    getDeviceList: function () {
      const tag = "[getDeviceList]";

      this.loading.show = true;

      let status = "";
      if (this.searchStatus === "Online") {
        status = "true";
      } else if (this.searchStatus === "Offline") {
        status = "false";
      } else {
        status = "";
      }

      this.$http
        .request({
          url:
            this.serverUri +
            `/v1/device/search?limit=${this.pagination.limit}&offset=${this.pagination.offset}&animalName=${this.searchAnimalName}&mac=${this.searchMacAddress}&isConnected=${status}&dewiStatus=${this.searchDewiStatus}`,
          headers: {
            Authorization: `Basic ${Vue.prototype.$credential.accessToken}`,
          },
        })
        .then((response) => {
          console.log(tag, response);

          if (response !== undefined && response.data !== undefined) {
            const resp = response.data.result;
            const deviceList = resp.result;

            if (deviceList !== null) {
              this.devices = [];

              for (
                let i = 0;
                i < (this.pagination.page - 1) * this.pagination.limit;
                i++
              ) {
                this.devices.push({});
              }
              console.log("pre device length: ", this.devices.length);

              const devicesJSON = JSON.parse(JSON.stringify(deviceList));

              for (let i = 0; i < resp.count / this.pagination.limit; i++) {
                devicesJSON.forEach((item) => {
                  this.insertIntoDevices(item);
                });
              }

              console.log("search count: ", resp.count);
              console.log("current device length: ", this.devices.length);

              for (let i = this.devices.length; i < resp.count; i++) {
                this.devices.push({});
              }
            } else {
              this.devices = [];
            }
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error.response);
          if (error.response.data.code === 9000600) {
            this.setLogin(false);
          }
        })
        .finally(() => {
          this.loading.show = false;
        });
    },
    insertIntoDevices(item) {
      let id = item.id;
      let createTime = item.createTime;
      let animalName = "";
      let onBoardingKey = "";
      let minerVersion = "";
      let status = "";
      let macAddress = "";
      let wifiMacAddress = "";
      let dewiStatus = item.dewiStatus;
      if (dewiStatus === "") {
        dewiStatus = "None";
      }
      let sn = "";
      let firmware = "";
      if (item.info != null) {
        animalName = item.info.animalName;
        onBoardingKey = item.info.onBoardingKey;
        macAddress = item.info.macAddress;
        wifiMacAddress = item.info.wifiMacAddress;
        sn = item.info.sn;
        firmware = item.info.firmware;
      }
      if (item.otaVersion != null) {
        minerVersion = item.otaVersion.minerVersion;
      }
      if (item.isConnected) {
        status = "Online";
      } else {
        status = "Offline";
      }
      const device = {
        id: id,
        createTime: createTime,
        animalName: animalName,
        onBoardingKey: onBoardingKey,
        minerVersion: minerVersion,
        status: status,
        macAddress: macAddress,
        wifiMacAddress: wifiMacAddress,
        dewiStatus: dewiStatus,
        sn: sn,
        firmware: firmware,
      };
      this.devices.push(device);
    },
    updateDocker(id) {
      const tag = "[updateDocker][" + id + "]";
      if (id === null) {
        console.error(tag, "device id not found");
      } else {
        this.loading.show = true;
        this.$http
          .post(this.serverUri + "/v1/device/" + id + "/imageUpdate")
          .then((response) => {
            console.log(tag, response);
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Update Docker Result";
            this.dialogInfo.content = `Start update progress successfully, wait a moment and refresh page`;
            this.dialogInfo.status = "md-success";
            this.loading.show = false;
          })
          .catch((error) => {
            console.error(error);
            console.log(error.response);
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Update Docker Result";
            this.dialogInfo.content = `Start update progress failed, error message: ${error.response.data.message}`;
            this.dialogInfo.status = "md-danger";
            this.loading.show = false;
          });
      }
    },
    reverseSSH(id) {
      const tag = "[reverseSSH][" + id + "]";
      console.log(tag, "click");

      if (id === null) {
        console.error(tag, "device id not found");
      } else {
        this.loading.show = true;
        this.$http
          .post(this.serverUri + "/v1/device/" + id + "/registerPort")
          .then((response) => {
            console.log(tag, response);
            const frpIp = response.data.result.ip;
            const frpPort = response.data.result.port;
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Reverse SSH Result";
            this.dialogInfo.content = `ssh -oPort=${frpPort} root@${frpIp}`;
            this.dialogInfo.status = "md-success";
            this.loading.show = false;
          })
          .catch((error) => {
            console.error(error);
            console.log(error.response);
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Reverse SSH Result";
            this.dialogInfo.content = error.response.data.message;
            this.dialogInfo.status = "md-danger";
            this.loading.show = false;
          });
      }
    },
    registerDewi(id) {
      const tag = "[registerDewi][" + id + "]";
      console.log(tag, "click");

      if (id === null) {
        console.error(tag, "device id not found");
      } else {
        this.loading.show = true;
        this.$http
          .request({
            url: this.serverUri + "/v1/dewi/" + id + "/register",
            method: 'post',
            headers: {
              Authorization: `Basic ${Vue.prototype.$credential.accessToken}`,
            }
          })
          .then((response) => {
            console.log(tag, response);
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Register DeWi";
            this.dialogInfo.content = `Register status [${response.data.result}]`;
            this.dialogInfo.status = "md-success";
            this.loading.show = false;

            this.getDeviceList();
          })
          .catch((error) => {
            console.error(error);
            console.log(error.response);
            this.dialogInfo.show = true;
            this.dialogInfo.title = "Register DeWi";
            this.dialogInfo.content = error.response.data.message;
            this.dialogInfo.status = "md-danger";
            this.loading.show = false;

            this.getDeviceList();
          });
      }
    },
  },
};
</script>
