<template>
  <div>
    <login v-if="showLogin"></login>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Vue from "vue";
import Login from "@/components/Login";
export default {
  components: {
    Login,
  },
  provide() {
    return {
      setLogin: this.setLogin,
    };
  },
  data() {
    return {
      showLogin: true,
    };
  },
  mounted() {
    const credential = window.sessionStorage.getItem("credential");
    if (credential) {
      Vue.prototype.$credential = JSON.parse(credential);
    }
    if (
      !Vue.prototype.$credential.isLogin &&
      this.$router.currentRoute.path !== "/login"
    ) {
      this.$router.push("/login");
    }
    this.showLogin = !Vue.prototype.$credential.isLogin;
  },
  methods: {
    setLogin(success) {
      console.log("setLogin success", success);
      if (success) {
        this.showLogin = false;
        this.$router.push("/devices");
      } else {
        this.showLogin = true;
        this.$router.push("/login");
      }
    },
  },
};
</script>
