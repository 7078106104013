import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Login from "@/components/Login";
import TableList from "@/pages/TableList.vue";

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/login",
    children: [
      {
        path: "devices",
        name: "Device List",
        component: TableList,
      },
    ],
  },
];

export default routes;
